import React, { useState } from 'react';

const TextifyApp = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('0');
  const [activeTab, setActiveTab] = useState('count');

  const transformations = {
    case: {
      uppercase: (text) => text.toUpperCase(),
      lowercase: (text) => text.toLowerCase(),
      titlecase: (text) => text.split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" "),
      sentencecase: (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    },
    format: {
      reverse: (text) => text.split("").reverse().join(""),
      removeSpaces: (text) => text.replace(/\s+/g, ""),
      singleSpaces: (text) => text.replace(/\s+/g, " ").trim(),
      uniqueLines: (text) => [...new Set(text.split("\n"))].join("\n"),
      removeEmpty: (text) => text.split("\n").filter(line => line.trim()).join("\n")
    },
    count: {
      characters: (text) => text.length,
      words: (text) => text.trim().split(/\s+/).filter(word => word.length > 0).length,
      lines: (text) => text.split("\n").filter(line => line.trim().length > 0).length,
      paragraphs: (text) => text.split("\n\n").filter(para => para.trim().length > 0).length,
      uniqueWords: (text) => new Set(text.toLowerCase().match(/\b\w+\b/g) || []).size
    },
    clean: {
      removeSpecialChars: (text) => text.replace(/[^a-zA-Z0-9\s]/g, ""),
      removeNumbers: (text) => text.replace(/[0-9]/g, ""),
      removeLinks: (text) => text.replace(/https?:\/\/[^\s]+/g, ""),
      removeEmails: (text) => text.replace(/[\w.-]+@[\w.-]+\.\w+/g, ""),
      smartQuotes: (text) => text.replace(/"/g, "\u201C").replace(/'/g, "\u2018")
    },
    encode: {
      base64Encode: (text) => btoa(text),
      base64Decode: (text) => {
        try {
          return atob(text);
        } catch {
          return 'Invalid Base64 input';
        }
      },
      urlEncode: (text) => encodeURIComponent(text),
      urlDecode: (text) => decodeURIComponent(text),
      escapeHTML: (text) => text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
    }
  };

  const handleTransform = (category, type) => {
    try {
      const result = transformations[category][type](input);
      setOutput(result.toString());
    } catch (error) {
      setOutput('Error processing text');
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(output);
  };

  const handleReset = () => {
    setInput('');
    setOutput('0');
  };

  const TabButton = ({ tab, label, icon }) => (
    <button
      onClick={() => setActiveTab(tab)}
      className={`px-4 py-2 rounded-lg font-medium transition-colors duration-200 
        ${activeTab === tab 
          ? 'bg-blue-500 text-white shadow-md' 
          : 'bg-white text-gray-700 hover:bg-gray-100'}`}
    >
      {icon} {label}
    </button>
  );

  const ActionButton = ({ onClick, label, icon, primary }) => (
    <button
      onClick={onClick}
      className={`px-4 py-2 rounded-lg font-medium transition-colors duration-200
        ${primary 
          ? 'bg-blue-500 text-white hover:bg-blue-600' 
          : 'bg-white text-gray-700 hover:bg-gray-100'} 
        border border-gray-200 shadow-sm`}
    >
      {icon} {label}
    </button>
  );

  const TransformButton = ({ label, onClick }) => (
    <button
      onClick={onClick}
      className="px-4 py-2 bg-white text-gray-700 rounded-lg font-medium 
        transition-colors duration-200 hover:bg-gray-100 border border-gray-200 shadow-sm"
    >
      {label}
    </button>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto p-8">
        <div className="mb-8 text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">Textify</h1>
          <p className="text-gray-600">Simple text transformation tools</p>
        </div>

        <div className="space-y-4">
          <div className="bg-white rounded-xl shadow-md p-6 space-y-4">
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter your text here..."
              className="w-full h-32 p-4 text-gray-700 border border-gray-200 rounded-lg 
                focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
            />
            
            <textarea
              value={output}
              readOnly
              className="w-full h-16 p-4 bg-gray-50 text-gray-700 border border-gray-200 
                rounded-lg resize-none"
            />

            <div className="flex gap-2">
              <ActionButton onClick={handleCopy} label="Copy" icon="📋" primary />
              <ActionButton onClick={handleReset} label="Reset" icon="↺" />
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-md p-6 space-y-4">
            <div className="flex flex-wrap gap-2">
              <TabButton tab="case" label="Case" icon="Aa" />
              <TabButton tab="format" label="Format" icon="⇅" />
              <TabButton tab="count" label="Count" icon="#" />
              <TabButton tab="clean" label="Clean" icon="🧹" />
              <TabButton tab="encode" label="Encode" icon="🔄" />
            </div>

            <div className="flex flex-wrap gap-2">
              {Object.entries(transformations[activeTab]).map(([key, _]) => (
                <TransformButton
                  key={key}
                  label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                  onClick={() => handleTransform(activeTab, key)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextifyApp;